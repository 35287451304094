import React from "react"
import Anchor from "../../core/components/anchor"
import Banner from "../../client/compositions/banner"
import Block from "../../core/components/block"
import Button from "../../client/compositions/button"
import RelatedProducts from "../../client/compositions/related-products"
import Decoration from "../../client/compositions/decoration"
import DecorationFluid from "../../client/compositions/decoration-fluid"
import Navigation from "../../core/components/navigation"
import Overlap from "../../client/compositions/overlap"
import Row from "../../core/components/row"
import Slider from "../../core/components/slider"
import { PortfolioTaglineMenu } from "../../client/compositions/tagline"
import { quickMenu } from "../../client/elements/menus"

/**
 * Project: Manhattan Page
 * @see https://zpl.io/bzgYOWl
 */
function ProjectManhattanPage() {
  return (
    <article
      id="page-portfolio-manhattan"
      className="page-content portfolio-page light-quick-menu"
    >
      {quickMenu}

      <Banner
        title={
          <>
            <div>Manhattan</div>
          </>
        }
        content={<>New York City, NY</>}
        className="flipped portfolio-banner"
      >
        <DecorationFluid image="portfolio-manhattan/banner.jpg" />
      </Banner>

      <Row id="summary" className="content-plus-media flipped">
        <Block className="block-content dropped">
          <div className="content">
            <dl className="details">
              <div className="detail">
                <dt className="title">Client</dt>
                <dd className="content">
                  Danny Meyer’s Union Square Hospitality Group (NYC)
                </dd>
              </div>
              <div className="detail">
                <dt className="title">Architect</dt>
                <dd className="content">Woods Bagot (NYC)</dd>
              </div>
              <div className="detail">
                <dt className="title">General Contractor</dt>
                <dd className="content">L&K Partners (NYC)</dd>
              </div>
              <div className="detail">
                <dt className="title">Bottle Capacity</dt>
                <dd className="content">2,304</dd>
              </div>
            </dl>
          </div>
        </Block>
        <Block className="block-media">
          <DecorationFluid image="/portfolio-manhattan/content-01.medium.jpg" />
        </Block>
      </Row>

      <Row id="features" className="content-plus-media">
        <Block className="block-content dropped">
          <h4 className="title">Features</h4>
          <div className="content">
            <ul className="features">
              <li className="feature">
                Fully conditioned 2 zone wine display.
              </li>
              <li className="feature">Custom dark stained walnut.</li>
              <li className="feature">Wine peg bottle system.</li>
              <li className="feature">Warm white LEDs.</li>
            </ul>
          </div>
        </Block>
        <Block className="block-media">
          <DecorationFluid image="/portfolio-manhattan/content-04.medium.jpg" />
        </Block>
      </Row>

      <Row id="gallery-two" className="single-media">
        <Block className="block-media">
          <Decoration image="/portfolio-manhattan/content-05.jpg" />
        </Block>
      </Row>

      <RelatedProducts
        id="related-products"
        products={[
          {
            slug: "premier",
            title: "Premier Collection",
            url: "products/premier/",
          },
        ]}
      />

      <PortfolioTaglineMenu portfolioSlug="portfolio-manhattan" />
    </article>
  )
}

export default ProjectManhattanPage
